:root{
    --bgcolor:#282c34;
}

.App {
    text-align: center;
    background-color: var(--bgcolor);
    transition: background-color .8s linear;
}

.App-header {
    min-height: 100vh;
    font-size: calc(10px + 2vmin);
    color: white;
    position: relative;
    overflow: hidden;
    transition: filter .3s;
}

.center{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.dialogue-text{
    font-size: calc(10px + 3vmin);
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-name: hinge;
  animation-name: hinge;
}

@keyframes hinge {
    0% {
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    20%,
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 80deg);
        transform: rotate3d(0, 0, 1, 80deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    40%,
    80% {
        -webkit-transform: rotate3d(0, 0, 1, 60deg);
        transform: rotate3d(0, 0, 1, 60deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        opacity: 1;
    }

    to {
        -webkit-transform: translate3d(0, 700px, 0);
        transform: translate3d(0, 700px, 0);
        opacity: 0;
    }
}