:root{
    --icon-width:2.2rem;
}
.desktop-button{
    background: radial-gradient(rgba(255,255,255,0.2) 30%, rgba(255,255,255,0.75) 100%);
    border-radius: 50%;
    position: absolute;
    right: 1rem;
    top: 4.2rem;
    display: flex;
    align-items:center;
    z-index:10;
    justify-content:flex-end;
    transition: all .3s ease-in-out;
}

.desktop-button-icon{
    width: var(--icon-width);
    height: var(--icon-width);

    background: url("./desktop.png") no-repeat center center /1.6rem ;
    cursor: pointer;
    user-select: none;
}
